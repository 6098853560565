import React from 'react';
import { Link, graphql } from 'gatsby';
import Markdown from 'react-markdown';

import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import CodeBlock from '../../components/CodeBlock';

import careerPostStyles from './career-post.module.scss';

export const query = graphql`
  query (
    $slug: String!
  ) {
    markdownRemark (
      fields: {
        slug: {
          eq: $slug
        }
      }
    ) {
      frontmatter {
        position_title,
        level,
        category,
        type,
        content,
        description,
        keywords
      }
      fields {
        slug
      }
    }
  }
`;

const CareerPost = props => {
  const keywords = props.data.markdownRemark.frontmatter.keywords[0].split(' ');
  const url = `https://techpods.co/careers/${props.data.markdownRemark.fields.slug}`;

  return (
    <Layout>
      <Seo 
        title={`${props.data.markdownRemark.frontmatter.position_title} | TechPods`}
        keywords= {keywords}
        description={props.data.markdownRemark.frontmatter.description}
        url={url}
      />
      <main className={careerPostStyles.careerPost}>
        <section className={careerPostStyles.container}>
          <h1 className="primary-title">{props.data.markdownRemark.frontmatter.position_title}</h1>
          <span>Sofia, Bulgaria</span>
          <hr />
          <h2 className="secondary-title">Description</h2>
          <Markdown
            source={props.data.markdownRemark.frontmatter.content} 
            renderers={{ code: CodeBlock }}
            className={careerPostStyles.content}
          ></Markdown>
          <Link to={`/contact?careerId=${props.data.markdownRemark.fields.slug}#form`} className="primary-btn" >Apply now</Link>
        </section>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="440"
          height="442"
          fill="none"
          viewBox="0 0 440 442"
        >
          <path
            stroke="#F0F4F7"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1.23"
            d="M76.772 131.844s171.176 11.947 176.951 12.305c5.819.358 148.392-46.76 148.392-46.76l150.765 25.058-51.479 116.073 56.448 145.158-253.946-42.554-227.132 21.031 46.958-81.125-46.957-149.186zM16.43 12.102L76.77 131.844M123.73 281.03l183.935 61.258M401.891 97.344l99.554 141.176M9.672 380.77l67.1-18.57M226.73 430.26L76.772 362.2M226.73 430.26l331.119-46.537M226.73 430.26l78.83-88.599M226.73 430.26l78.83-88.599M16.43 12.102l237.293 132.047M401.891 97.344L581.886 19.71M303.366 45.438l-49.643 98.711M401.892 97.344l-98.525-51.906M16.43 12.102l286.936 33.336M123.73 281.03L9.672 380.77"
          ></path>
          <path
            fill="#F0F4F7"
            stroke="#F0F4F7"
            strokeMiterlimit="10"
            strokeWidth="1.02"
            d="M80.711 127.638c2.328 2.192 2.417 5.817.224 8.144-2.194 2.327-5.82 2.416-8.147.224a5.74 5.74 0 01-.224-8.144c2.194-2.282 5.82-2.417 8.147-.224z"
          ></path>
          <path
            fill="#F0F4F7"
            stroke="#F0F4F7"
            strokeMiterlimit="10"
            strokeWidth="1.7"
            d="M407.798 91.08c3.492 3.266 3.626 8.77.359 12.216-3.268 3.445-8.729 3.624-12.221.358-3.492-3.267-3.626-8.77-.358-12.216 3.312-3.49 8.774-3.625 12.22-.358zM257.037 140.659c1.924 1.835 2.014 4.878.179 6.802-1.836 1.924-4.88 2.013-6.804.179-1.925-1.835-2.015-4.878-.18-6.802 1.836-1.924 4.88-2.014 6.805-.179zM5.284 12.46C5.105 6.285 9.94 1.184 16.072 1.005c6.133-.18 11.28 4.653 11.46 10.784.179 6.13-4.656 11.276-10.788 11.455-6.133.179-11.28-4.654-11.46-10.784zM298.307 45.617c-.089-2.774 2.104-5.146 4.924-5.19 2.776-.09 5.148 2.103 5.193 4.922.09 2.774-2.104 5.146-4.924 5.19-2.775.045-5.103-2.147-5.193-4.922z"
          ></path>
          <path
            fill="#F0F4F7"
            stroke="#F0F4F7"
            strokeMiterlimit="10"
            strokeWidth="1.02"
            d="M80.308 358.397c2.104 1.968 2.193 5.235.224 7.338a5.177 5.177 0 01-7.341.224 5.173 5.173 0 01-.224-7.339c1.97-2.058 5.282-2.192 7.341-.223z"
          ></path>
          <path
            fill="#F0F4F7"
            stroke="#F0F4F7"
            strokeMiterlimit="10"
            strokeWidth="1.7"
            d="M15.58 373.834c3.491 3.267 3.626 8.77.358 12.216-3.268 3.445-8.73 3.624-12.22.358-3.492-3.267-3.627-8.77-.359-12.216 3.268-3.49 8.774-3.624 12.22-.358z"
          ></path>
          <path
            fill="#F0F4F7"
            stroke="#F0F4F7"
            strokeMiterlimit="10"
            strokeWidth="1.02"
            d="M127.67 276.868c2.328 2.193 2.417 5.817.224 8.144-2.194 2.327-5.819 2.416-8.147.224-2.328-2.193-2.417-5.817-.224-8.144 2.193-2.327 5.864-2.416 8.147-.224z"
          ></path>
          <path
            fill="#F0F4F7"
            stroke="#F0F4F7"
            strokeMiterlimit="10"
            strokeWidth="1.7"
            d="M233.985 422.832c4.252 3.982 4.431 10.694.447 14.945-3.984 4.251-10.698 4.43-14.951.448-4.253-3.983-4.432-10.695-.448-14.946 3.984-4.251 10.699-4.43 14.952-.447zM308.65 336.739a5.75 5.75 0 01.269 9.576c-1.881 1.342-4.477 1.432-6.402.179a5.75 5.75 0 01-.268-9.576c1.835-1.342 4.431-1.432 6.401-.179z"
          ></path>
        </svg>
      </main>
    </Layout>
  );
};

export default CareerPost;